.hits {
  @apply relative min-w-full sm:min-w-fit;

  & .header {
    @apply border border-gray-200 p-2 text-gray-500;
    @apply transition-[border] duration-300 ease-in-out;

    & button {
      @apply flex w-full items-center justify-between;
    }

    & .leftColumn {
      @apply flex items-center justify-start gap-2;
    }
  }

  & .title {
    @apply mb-0;
    @apply font-secondary text-lg font-bold uppercase tracking-tighter text-gray-800;

    & + span {
      @apply whitespace-nowrap text-sm lg:text-base;
    }
  }

  & .list {
    @apply invisible absolute left-0 top-0 z-50 mt-12 w-full opacity-0;
    @apply border border-gray-200;
    @apply transition-opacity duration-300 ease-in-out;
  }

  & .label {
    @apply border-b bg-white p-4;
    @apply text-gray-600 hover:cursor-pointer hover:text-gray-800;
  }

  &.expanded {
    & .header {
      @apply border-red-base;
    }

    & .list {
      @apply visible opacity-100;
    }
  }
}
