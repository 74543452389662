.card {
  @apply relative z-10 mx-auto h-full w-full cursor-pointer;
  @apply bg-white font-secondary shadow-[0_2px_10px_0_rgba(164,163,163,0.5)];
  @apply flex flex-col;
  @apply lg:hover:z-20 lg:hover:scale-125;
  @apply @container/card;

  @media (width >= calc(theme('screens.lg'))) {
    transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      transition:
        transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 500ms,
        z-index 100ms cubic-bezier(0.4, 0, 0.2, 1) 500ms;

      & :global(.swiper-button-prev),
      & :global(.swiper-button-next),
      & .socialIcons,
      & .estimate,
      & .runDate {
        @apply visible opacity-100 transition-opacity delay-500 duration-300;
      }
    }

    &:focus-within {
      & :global(.swiper-button-prev),
      & :global(.swiper-button-next),
      & .socialIcons,
      & .estimate,
      & .runDate {
        @apply visible opacity-100 transition-none;
      }
    }

    &:hover,
    &:focus-within {
      & .runDate {
        @apply opacity-0;
      }
    }
  }

  &:hover,
  &:focus-within {
    & .watchlist {
      @apply visible opacity-100;
    }
  }

  & .imageWrap {
    @apply relative;

    & :global(.swiper) {
      @apply absolute inset-0 z-10;
    }
  }

  & .link {
    @apply absolute z-[1] h-full w-full;
  }

  & .saleResultDetails {
    @apply flex w-full flex-wrap justify-between;

    &:has(.priceBadge) {
      @apply bg-white;

      & .saleResultBadge {
        @apply after:bg-transparent;
      }
    }
  }

  & .saleResultBadge {
    @apply absolute z-[11] before:hidden after:hidden;

    &:global(.sold) {
      @apply -left-2 px-0;
    }

    &:global(.bid-goes-on) {
      @apply pl-3;
      @apply after:-z-[1] after:flex after:w-6 after:[left:unset];
      @apply after:-right-3 after:-skew-x-[25deg];
    }

    & :global(.soldBadge) {
      @apply mt-[-7px] max-w-[50px] object-contain;
    }

    & :global(.bidGoesOnBadge) {
      @apply h-auto max-w-[130px] object-contain;
    }
  }

  & .priceBadge {
    @apply z-[2] justify-end px-3 text-lg;
    @apply after:[width:unset];
  }

  & .saleResultBadge + .priceBadge,
  & .priceBadge:only-child {
    @apply w-full;
  }

  & header {
    & > a {
      @apply no-underline;
    }

    & > a > img {
      @apply aspect-video object-cover;
    }

    & .slide {
      @apply !w-full;

      & img {
        @apply aspect-video object-cover;
      }
    }
  }

  & :global(.swiper-button-prev),
  & :global(.swiper-button-next) {
    @apply z-20 [transform:translateZ(0)] after:text-3xl after:text-gray-300 lg:invisible lg:opacity-0;

    &:hover,
    &:focus {
      @apply after:text-white;
    }

    & span {
      @apply sr-only;
    }
  }

  & :global(.swiper-button-prev) {
    @apply after:content-['prev'];
  }

  & :global(.swiper-button-next) {
    @apply after:content-['next'];
  }

  & .container {
    @apply grid h-full grid-rows-[min-content_min-content_1fr] p-4 pt-2;
  }

  & .auction {
    @apply z-[1] w-fit font-secondary text-lg font-bold uppercase text-red-base;
  }

  & .titleContainer {
    @apply flex justify-between;
  }

  & .title {
    @apply relative z-[1] block text-2xl font-bold leading-8;
  }

  & .watchlist {
    @apply z-[2] self-start;
    @apply transition-opacity duration-300;
    @apply lg:invisible lg:opacity-0;

    & svg {
      @apply ml-0 h-[20px] w-[20px] transition-all;
    }

    & button {
      @apply ml-1 flex h-8 w-8 items-center justify-center;
    }
  }

  & .series {
    @apply text-base italic text-gray-500;
  }

  & .prebid {
    @apply mb-2 mt-4 flex flex-col items-center gap-3;

    & .bidNow {
      @apply z-[1] w-full border-none bg-green-base font-secondary tracking-normal;
      @apply hover:!bg-green-hover;
    }

    & .currentBid {
      @apply line-clamp-1 block h-4 text-base uppercase leading-none;

      & > span {
        @apply font-bold;
      }
    }
  }

  & .footer {
    @apply mt-4 flex items-end justify-between self-end;
  }

  & .estimateRunDate {
    @apply text-[0.75rem] font-bold uppercase leading-4;

    & span {
      @apply block text-gray-600;
    }
  }

  & .estimate {
    @apply lg:absolute lg:opacity-0;
  }

  & .socialIcons {
    @apply z-[1] mb-0 flex gap-1;
    @apply lg:invisible lg:opacity-0;

    & li {
      @apply flex;
    }
  }
}
