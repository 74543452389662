:global(div) + .startYourExperience {
  /* Position component when it follows a carousel. */
  @apply -mt-4 lg:-mt-20 tall:-mt-24 short:-mt-12;
}

.startYourExperience {
  @apply relative z-40 my-0 mb-10 align-full lg:align-initial xl:align-stretched 2xl:align-initial;

  & .link {
    @apply text-sm text-gray-400 underline hover:text-white;
  }

  & .intro {
    @apply mb-8;

    & h3 {
      @apply -mb-0.5 font-secondary text-xl text-white;
    }

    & p {
      @apply text-gray-400;
    }
  }

  /* Form layout */
  & .formWrapper {
    @apply mx-auto w-full xl:max-w-3xl;
  }

  & .panels {
    @apply rounded-b-md bg-gray-900;

    & > section {
      @apply hidden px-8 py-6;

      & label {
        @apply text-white;
      }

      &.active {
        @apply block;
      }
    }
  }
}
