.tabs {
  @apply my-8;

  & .tabList {
    @apply mx-2 flex xs:mx-8 lg:mx-auto;

    &:global(.tabsPerView-1) {
      @apply lg:w-[300px];
    }

    &:global(.tabsPerView-2) {
      @apply lg:w-[600px];
    }

    &:global(.tabsPerView-3) {
      @apply lg:w-[900px];

      & .tabButton {
        @apply sm:w-1/3;
      }
    }

    &:global(.tabsPerView-4) {
      @apply lg:w-[900px];

      & .tabButton {
        @apply sm:w-1/4;
      }
    }

    &:global(.tabsPerView-5) {
      @apply lg:w-[900px];

      & .tabButton {
        @apply sm:w-1/5;
      }
    }

    & .swiper {
      @apply w-full;
    }

    & :global(.swiper-button-prev),
    & :global(.swiper-button-next) {
      @apply bottom-0 top-0 z-[9999] m-0 h-full w-6 bg-white text-gray-500 transition-all hover:text-red-base;

      &::after {
        @apply text-base;
      }

      &:global(.swiper-button-disabled),
      &:global(.swiper-button-lock) {
        @apply invisible opacity-0;
      }
    }

    & :global(.swiper-button-prev) {
      @apply left-0;
    }

    & :global(.swiper-button-next) {
      @apply right-0;
    }

    & .tabButton {
      @apply flex w-fit items-end border-b border-b-black;

      &[aria-selected='true'] {
        @apply border-milano-red;
      }

      & button {
        @apply flex-1 cursor-pointer border-b-[5px] border-transparent px-1 py-3 font-primary text-xl lg:px-1 lg:text-2xl;
        @apply flex h-full w-full items-end justify-center;

        & img {
          @apply w-full;
        }

        &.isActive {
          @apply border-b-milano-red text-2xl font-bold lg:text-4xl;
        }
      }
    }
  }

  & .tabPanel {
    @apply hidden py-8 focus:outline-none;

    &.isActive {
      @apply block;
    }
  }

  & .swiper {
    @apply relative px-6;
  }
}
